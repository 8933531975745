import React from "react";

export default function Footer() {
	// get current year
	const year = new Date().getFullYear();

	return (
		<section className="landing-footer landing-footer--light">
			<div className="boxed">
				<div className="row justify-content-center justify-content-md-between align-items-center text-center">
					<div className="col-md-auto">
						<a href="https://trellushealth.com/">
							<img src={process.env.REACT_APP_TRELLUS_HEALTH_LOGO_URL} alt="trellus-logo" width="146" />
						</a>
					</div>
					<div className="auto">
					</div>
				</div>
			</div>
			<div className="boxed mt-3">
				<div className="row text-center text-md-left text_xs g-5 row-divider-md landing_copyright">
					<div className="col-md-auto my-1 my-0-md">
						<p>©{year} Trellus Health®, Inc</p>
					</div>
					<div className="col-md-auto my-1 my-0-md">
						<a
							href="https://trellushealth.com/terms-of-use"
							target="_blank"
							rel="noopener noreferrer"
							className="btn-link btn-link-darkness h-text-underline"
						>
							Terms of Use
						</a>
					</div>
					<div className="col-md-auto my-1 my-0-md">
						<a
							href="https://trellushealth.com/privacy-policy"
							target="_blank"
							rel="noopener noreferrer"
							className="btn-link btn-link-darkness h-text-underline"
						>
							Privacy Policy
						</a>
					</div>
					<div className="col-md my-1 my-0-md">
						<p>
							The information provided on the Trellus Health® website, in any
							communications to Trellus Health® users, and in any downloads made
							available to Trellus Health® users is intended to be general health
							information for the sole purpose of facilitating such users'
							ability to obtain appropriate care from their healthcare
							professionals and does not constitute opinions, medical or nursing
							care, medical advice, diagnosis, or treatment.
						</p>
					</div>
					<div className="col-md-auto my-1 my-0-md">
						<a href="tel:18008664165" className="btn-link btn-link-darkness">
							1-800-989-5503
						</a>
						<br />
						<a
							href="mailto:support@trellushealth.com"
							className="btn-link btn-link-darkness h-text-underline"
						>
							support@trellushealth.com
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}
