const BASE_URL = process.env.REACT_APP_TEP_API_BASE_URL;
const TEP_API_URL = `${BASE_URL}/payment/api`

let token = null;

// create auth code
export async function createAuthCode(patientInfo, authCodeType) {
	const patientRequest = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			firstName: patientInfo.firstName,
			lastName: patientInfo.lastName,
			email: patientInfo.email,
			phone: patientInfo.mobile.replace("+1", ""),
			verificationType: authCodeType,
		}),
	};

	try {
		const response = await fetch(
			`${BASE_URL}/provider/api/ExternalRegistration`,
			patientRequest
		);
		return await response.json();
	} catch (e) {
		console.error(e);
		return e;
	}
}

// confirm auth code
export async function confirmAuthCode(externalRegistrationId, confirmationCode) {
	const patientRequest = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			externalRegistrationId,
			confirmationCode
		}),
	};

	try {
		const response = await fetch(
			`${BASE_URL}/provider/api/ExternalRegistration/confirm`,
			patientRequest
		);
		return await response.json();
	} catch (e) {
		console.error(e);
		return e;
	}
}

// set Bearer token used for Authorization header
export function setToken(accessToken) {
	token = accessToken;
}

// check if customer exists in Stripe
export async function isCustomerExists(email) {
	try {
		const request = await fetch(
			`${TEP_API_URL}/Stripe/iscustomerexists?email=${email}`
		);
		return await request.json();
	} catch (e) {
		console.error(e);
		return e;
	}
}

// check if email is subscribed in Elevate
export async function isCustomerSubscribed(email) {
	try {
		const request = await fetch(
			`${TEP_API_URL}/Stripe/iscustomersubscribed?email=${email}`
		);
		return await request.json();
	} catch (e) {
		console.error(e);
		return e;
	}
}

// create patient account in Elevate
export async function createPatient(patientInfo) {
	const patientRequest = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			firstName: patientInfo.firstName,
			lastName: patientInfo.lastName,
			email: patientInfo.email,
			phone: patientInfo.mobile.replace("+1", ""),
			dateOfBirth: patientInfo.dob.toISOString(),
			country: parseInt(patientInfo.country),
			state: patientInfo.state,
			city: patientInfo.city,
			address: patientInfo.address + " " + patientInfo.address2,
			zipCode: patientInfo.zipcode,
			sex: patientInfo.gender,
			specialtyId: patientInfo.specialty,
			diagnosisId: patientInfo.diagnosis,
		}),
	};

	try {
		const response = await fetch(
			`${TEP_API_URL}/Payment/patient`,
			patientRequest
		);
		return await response.json();
	} catch (e) {
		console.error(e);
		return e;
	}
}

// send patient account invite from Elevate
export async function sendPatientInvite(patient_id) {
	const inviteRequest = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	try {
		const response = await fetch(
			`${TEP_API_URL}/Payment/invite/${patient_id}`,
			inviteRequest
		);
		return await response.json();
	} catch (e) {
		console.error(e);
		return e;
	}
}

// validate a Stripe coupon code
export async function validateCoupon(code) {
	try {
		const response = await fetch(`${TEP_API_URL}/Stripe/coupon?Code=${code}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return await response.json();
	} catch (e) {
		console.error(e);
		return e
	}
}

// create a customer in Stripe
export async function createCustomer(patientInfo, paymentMethodId) {
	const customerRequest = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			payment_method: paymentMethodId,
			name: (patientInfo.billingFirstName || patientInfo.firstName) +
				" " + (patientInfo.billingLastName || patientInfo.lastName),
			email: patientInfo.email,
			phone: patientInfo.mobile.replace("+1", ""),
			address: {
				city: patientInfo.billingCity || patientInfo.city,
				country: parseInt(patientInfo.billingCountry || patientInfo.country) === 1 ? "USA" : "Canada",
				line1: patientInfo.billingAddress || patientInfo.address,
				line2: patientInfo.billingAddress2 || patientInfo.address2,
				postalCode: patientInfo.billingZipcode || patientInfo.zipcode,
				state: patientInfo.billingState || patientInfo.state,
			},
		}),
	};

	try {
		const response = await fetch(
			`${TEP_API_URL}/Stripe/customer`,
			customerRequest
		);
		return await response.json();
	} catch (e) {
		console.error(e);
		return e;
	}
}

// set a payment method as default in Stripe
export async function setDefaultPaymentMethod(paymentMethodId) {
	const request = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		}
	};
	try {
		const response = await fetch(`${TEP_API_URL}/Stripe/setdefaultpaymentmethod?paymentMethodId=${paymentMethodId}`, request);
		return await response.json();
	} catch (e) {
		console.error(e);
		return e;
	}
}

// create a subscription in Stripe
export async function createSubscription(couponId) {
	let requestUrl = `${TEP_API_URL}/Stripe/subscription`;
	const subscriptionRequest = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	};

	if (couponId) {
		requestUrl = `${TEP_API_URL}/Stripe/subscription?promoCodeId=${couponId}`;
	}

	try {
		const response = await fetch(requestUrl, subscriptionRequest);
		return await response.json();
	} catch (e) {
		console.error(e);
		return e;
	}
}

export async function createStrengthsAssessment(patientId, strengthAssessmentData) {
	if (strengthAssessmentData.length === 0) return;

	const body = {
		items: [
			{ "question": "q_strengths_assessment_how_satisfied_with_life", "value": strengthAssessmentData.find(x => x.key === "strength_3_all_things")?.number },
			{ "question": "q_strengths_assessment_avoid_situations", "value": strengthAssessmentData.find(x => x.key === "strength_4_avoid")?.number },
			{ "question": "q_strengths_assessment_rid_of_ibd_symptoms", "value": strengthAssessmentData.find(x => x.key === "strength_5_need")?.number },
			{ "question": "q_strengths_assessment_able_to_adapt", "value": strengthAssessmentData.find(x => x.key === "strength_6_able")?.number },
			{ "question": "q_strengths_assessment_tend_to_bounce", "value": strengthAssessmentData.find(x => x.key === "strength_7_tend")?.number },
			{ "question": "q_strengths_assessment_expect_the_best", "value": strengthAssessmentData.find(x => x.key === "strength_8_uncertain")?.number },
			{ "question": "q_strengths_assessment_have_someone_trust", "value": strengthAssessmentData.find(x => x.key === "strength_9_have")?.number },
			{ "question": "q_strengths_assessment_feel_grateful_each_day", "value": strengthAssessmentData.find(x => x.key === "strength_10_feel")?.number },
		],
		"patientId": patientId
	}
	const request = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body)
	}

	try {
		const response = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/payment/api/Assessments/strengthassessment`, request)
		return await response.json();
	} catch (e) {
		console.error(e)
	}
}

export async function createDiseaseSeverityAssessment(patientId, diseaseSeverityAssessmentData) {
	if (diseaseSeverityAssessmentData.length === 0) return;

	const type = diseaseSeverityAssessmentData.find(x => x.key === "q_dss_ibd_everity_classifier")?.text;
	let items = [];
	if (type === "Crohn's Disease") {
		items = [
			{ "question": "q_dss_ibd_everity_classifier", "value": type },
			{ "question": "q_dss_year_of_crohn_diagnosis", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_year_of_crohn_diagnosis")?.text },
			{ "question": "q_dss_concerns_adherence_care_plans_or_recommendations", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_concerns_adherence_care_pl")?.text },
			{ "question": "q_dss_have_hospitalized_for_ibd_least_12_months", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_have_hospitalized_for_ibd")?.text },
			{ "question": "q_dss_have_ileostomy_colostomy", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_have_ileostomy_colostomy")?.text },
			{ "question": "q_dss_have_surgery_of_crohn_to_remove_part_of_intestine", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_have_surgery_of_crohn_to")?.text },
			{ "question": "q_dss_have_used_prednisone_for_least_3_months", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_have_used_prednisone_for_l")?.text },
			{ "question": "q_dss_managed_following_medications", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_managed_following_medicati")?.text },
			{ "question": "q_dss_have_failed_least_2_of_medications_listed_in_statement_6", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_have_failed_least_2_of_med")?.text },
		]
	} else if (type === "Ulcerative Colitis") {
		items = [
			{ "question": "q_dss_ibd_everity_classifier", "value": type },
			{ "question": "q_dss_ibd_type", "value": type },
			{ "question": "q_dss_year_of_diagnosis", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_year_of_diagnosis")?.text },
			{ "question": "q_dss_have_hospitalized_for_ibd_least_12_months", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_have_hospitalized_for_ibd")?.text },
			{ "question": "q_dss_have_used_prednisone_for_least_3_months", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_have_used_prednisone_for_l")?.text },
			{ "question": "q_dss_managed_following_medications", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_managed_following_medicati")?.text },
			{ "question": "q_dss_have_failed_least_2_of_medications_listed_in_statement_4", "value": diseaseSeverityAssessmentData.find(x => x.key === "q_dss_have_failed_least_2_of_med")?.text },
		]
	} else if (type === "Irritable Bowel Syndrome") {
		items = [
			{ "question": "q_ibs_dss_currently_suffer_from_abdominal_pain", "value": diseaseSeverityAssessmentData.find((x) => x.key === "q_ibs_dss_currently_suffer_from")?.text },
			{ "question": "q_ibs_dss_days_you_had_abdominal_pain_past_10_days", "value": diseaseSeverityAssessmentData.find((x) => x.key === "q_ibs_dss_days_you_had_abdominal")?.number },
			{ "question": "q_ibs_dss_do_you_suffer_from_abdominal_distention", "value": diseaseSeverityAssessmentData.find((x) => x.key === "q_ibs_dss_do_you_suffer_from_abd")?.text },
			{ "question": "q_ibs_dss_how_dissatisfied_you_with_your_bowel_functioning", "value": diseaseSeverityAssessmentData.find((x) => x.key === "q_ibs_dss_how_dissatisfied_you_w")?.number * 10 },
			{ "question": "q_ibs_dss_how_much_did_abdominal_pain_past_10_days", "value": diseaseSeverityAssessmentData.find((x) => x.key === "q_ibs_dss_how_much_did_abdominal")?.number * 10 },
			{ "question": "q_ibs_dss_how_severe_was_abdominal_pain", "value": diseaseSeverityAssessmentData.find((x) => x.key === "q_ibs_dss_how_severe_was_abdomi")?.number * 10 },
			{ "question": "q_ibs_dss_how_severe_was_abdominal_distention_past_10_days", "value": diseaseSeverityAssessmentData.find((x) => x.key === "q_ibs_dss_how_severe_was_abdomin")?.number * 10 },
		]
	} else {
		return;
	}

	const body = {
		"items": items,
		"patientId": patientId
	}
	const request = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body)
	}

	try {
		const response = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/payment/api/Assessments/diseaseseverityscreener`, request)
		return await response.json();
	} catch (e) {
		console.error(e)
	}
}

export async function createSelfEfficacyAdultAssessment(patientId, selfEfficacyAdultAssessmentData) {
	if (selfEfficacyAdultAssessmentData.length === 0) return;

	const body = {
		"items": [
			{ "dynamicSectionIndex": null, "question": "q_make_yourself_feel_better", "value": selfEfficacyAdultAssessmentData.find(x => x.key === "self_adult_do_something")?.number },
			{ "dynamicSectionIndex": null, "question": "q_follow_instructions_for_medication", "value": selfEfficacyAdultAssessmentData.find(x => x.key === "self_adult_follow_the")?.number },
			{ "dynamicSectionIndex": null, "question": "q_keep_fatigue_from_interfering", "value": selfEfficacyAdultAssessmentData.find(x => x.key === "self_adult_keep_fatigue")?.number },
			{ "dynamicSectionIndex": null, "question": "q_get_good_nights_sleep_se", "value": selfEfficacyAdultAssessmentData.find(x => x.key === "self_adult_get_a")?.number },
			{ "dynamicSectionIndex": null, "question": "q_keep_physical_discomfort_or_pain_from_interfering", "value": selfEfficacyAdultAssessmentData.find(x => x.key === "self_adult_keep_or")?.number },
			{ "dynamicSectionIndex": null, "question": "q_maintain_your_well_being", "value": selfEfficacyAdultAssessmentData.find(x => x.key === "self_adult_maintain_your")?.number },
			{ "dynamicSectionIndex": null, "question": "q_keep_or_put_your_idb_into_remission", "value": selfEfficacyAdultAssessmentData.find(x => x.key === "self_adult_keep_you")?.number },
		],
		"patientId": patientId
	}
	const request = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body)
	}

	try {
		const response = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/payment/api/Assessments/selfefficacy`, request)
		return await response.json();
	} catch (e) {
		console.error(e)
	}
}

export async function createSelfEfficacyPediatricAssessment(patientId, selfEfficacyPediatricAssessmentData) {
	if (selfEfficacyPediatricAssessmentData.length === 0) return;

	const body = {
		"dynamicSectionIndex": "",
		"items": [
			{ "dynamicSectionIndex": null, "question": "q_understand_what_ibd_is", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_1_i_undertand")?.number },
			{ "dynamicSectionIndex": null, "question": "q_could_explain_what_colonoscopy_for", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_2_if_some")?.number },
			{ "dynamicSectionIndex": null, "question": "q_remembering_to_take_my_medications_is_hard", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_3_remember")?.number },
			{ "dynamicSectionIndex": null, "question": "q_can_get_through_my_day_even_if_have_symptoms", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_4_i_get_throu")?.number },
			{ "dynamicSectionIndex": null, "question": "q_worry_about_ibd_affect_my_future", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_5_i_worry")?.number },
			{ "dynamicSectionIndex": null, "question": "q_remember_names_of_my_ibd_medications", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_6_when_ask")?.number },
			{ "dynamicSectionIndex": null, "question": "q_have_someone_to_turn_when_frustrated", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_7_when_i")?.number },
			{ "dynamicSectionIndex": null, "question": "q_feel_comfortable_talking_to_doctor", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_8_i_feel")?.number },
			{ "dynamicSectionIndex": null, "question": "q_can_find_foods_that_i_can_eat", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_9_no_matter")?.number },
			{ "dynamicSectionIndex": null, "question": "q_know_what_to_do_when_think_flare_starting", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_10_i_undertan")?.number },
			{ "dynamicSectionIndex": null, "question": "q_know_where_to_find_reliable_answer", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_11_i_kno_whe")?.number },
			{ "dynamicSectionIndex": null, "question": "q_know_what_will_make_me_feel_better", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_12_i_kno_wha")?.number },
			{ "dynamicSectionIndex": null, "question": "q_hopeful_my_ibd_symptoms_will_get_better", "value": selfEfficacyPediatricAssessmentData.find(x => x.key === "self_efficacy_peds_13_i_hope")?.number },
		],
		"patientId": patientId
	}

	const request = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body)
	}

	try {
		const response = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/payment/api/Assessments/selfefficacy`, request)
		return await response.json();
	} catch (e) {
		console.error(e)
	}
}

export async function createIBSSelfEfficacyAssessment(patientId, data) {
	const requestBody = {
		items: [
			{ "question": "q_ibs_follow_instructions_for_medication", "value": data.find((x) => x.key === "q_ibs_follow_instructions_for_me")?.number },
			{ "question": "q_ibs_get_good_nights_sleep_se", "value": data.find((x) => x.key === "q_ibs_get_good_nights_sleep_se")?.number },
			{ "question": "q_ibs_keep_fatigue_from_interfering", "value": data.find((x) => x.key === "q_ibs_keep_fatigue_from_interfer")?.number },
			{ "question": "q_ibs_keep_or_put_your_ibs_into_remission", "value": data.find((x) => x.key === "q_ibs_keep_or_put_your_ibs_into")?.number },
			{ "question": "q_ibs_keep_physical_discomfort_or_pain_from_interfering", "value": data.find((x) => x.key === "q_ibs_keep_physical_discomfort_o")?.number },
			{ "question": "q_ibs_maintain_your_well_being", "value": data.find((x) => x.key === "q_ibs_maintain_your_well_being")?.number },
			{ "question": "q_ibs_make_yourself_feel_better", "value": data.find((x) => x.key === "q_ibs_make_yourself_feel_better")?.number },
		],
		patientId: patientId
	}
	try {
		const assessmentRequest = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(requestBody),
		};
		const response = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/payment/api/Assessments/selfefficacy`, assessmentRequest)
		return await response.json();
	} catch (e) {
		console.error(e);
	}
}

export async function createIBSStrengthAssessment(patientId, data) {
	const requestBody = {
		items: [
			{ "question": "q_ibs_strengths_assessment_able_to_adapt", "value": data.find((x) => x.key === "q_ibs_strengths_assessment_able")?.number },
			{ "question": "q_ibs_strengths_assessment_avoid_situations", "value": data.find((x) => x.key === "q_ibs_strengths_assessment_avoid")?.number },
			{ "question": "q_ibs_strengths_assessment_expect_the_best", "value": data.find((x) => x.key === "q_ibs_strengths_assessment_expec")?.number },
			{ "question": "q_ibs_strengths_assessment_feel_grateful_each_day", "value": data.find((x) => x.key === "q_ibs_strengths_assessment_feel")?.number },
			{ "question": "q_ibs_strengths_assessment_have_someone_trust", "value": data.find((x) => x.key === "q_ibs_strengths_assessment_have")?.number },
			{ "question": "q_ibs_strengths_assessment_how_satisfied_with_life", "value": data.find((x) => x.key === "q_ibs_strengths_assessment_how_s")?.number },
			{ "question": "q_ibs_strengths_assessment_rid_of_symptoms", "value": data.find((x) => x.key === "q_ibs_strengths_assessment_rid_o")?.number },
			{ "question": "q_ibs_strengths_assessment_tend_to_bounce", "value": data.find((x) => x.key === "q_ibs_strengths_assessment_tend")?.number },
		],
		patientId: patientId
	}
	try {
		const assessmentRequest = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(requestBody),
		};
		const response = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/payment/api/Assessments/strengthassessment`, assessmentRequest)
		return await response.json();
	} catch (e) {
		console.error(e)
	}
}


// provider functions

// check if email is already registered (checks whole user table for patients and providers)
export async function checkEmail(email) {
	try {
		const emailExists = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/provider/api/ExternalRegistration/emailcheck?email=${encodeURIComponent(email)}`);
		const result = await emailExists.json();
		return result;
	} catch (e) {
		console.error(e)
	}
}

// get an auth code for provider registration
export async function getAuthCode(email, mobile, firstName, lastName, type) {
	const authCodeRequest = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(
			{
				"email": email,
				"phone": mobile,
				"verificationType": type, // 0 - Email, 1 - SMS
				"firstName": firstName,
				"lastName": lastName
			}
		)
	}

	try {
		const authCodeResponse = await fetch(
			`${process.env.REACT_APP_TEP_API_BASE_URL}/provider/api/ExternalRegistration`,
			authCodeRequest
		);
		return await authCodeResponse.json();
	} catch (e) {
		console.error(e);
	}
}

// get a list of countries
export async function getCountries() {
	try {
		const countries = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/provider/api/location/countries`)
		return await countries.json()
	} catch (e) {
		console.error(e)
	}
}

// get a list of states for a country
export async function getStates(country) {
	try {
		const states = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/provider/api/location/states?country=${country}`)
		return await states.json()
	} catch (e) {
		console.error(e)
	}
}

// get provider types
export async function getProviderTypes() {
	try {
		const types = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/provider/api/ExternalRegistration/providertypes`)
		return await types.json()
	} catch (e) {
		console.error(e)
	}
}

// get provider organizations
export async function getProviderOrganizations() {
	try {
		const orgs = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/provider/api/ExternalRegistration/organizations`)
		return await orgs.json()
	} catch (e) {
		console.error(e)
	}
}

// complete provider registration, create account in Elevate
export async function completeRegistration(token, provider) {
	const confirmRequest = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(
			{
				"address": provider.address.line1,
				"middleInitial": provider.middleInitial,
				"npiNumber": provider.npiNumber,
				"officePhone": provider.officePhone.slice(2), // remove country code
				"providerOrganization": provider.providerOrganization,
				"providerState": provider.address.state,
				"providerTitleId": provider.titleId,
				"providerTypeId": 2, // Physician
				"zip": provider.address.zip
			}
		)
	}

	try {
		const response = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/provider/api/ExternalRegistration/completeproviderregistration`,
			confirmRequest)
		return response
	} catch (e) {
		console.error(e)
	}
}

// send invite from Elevate
export async function sendInvite(token) {
	const confirmRequest = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
		}
	}

	try {
		const response = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/provider/api/ExternalRegistration/sendinvitation`,
			confirmRequest)
		return response
	} catch (e) {
		console.error(e)
	}
}

// complete patient registration, create account in Elevate
export async function completePatientRegistration(token, patient) {
	console.log(patient);
	const confirmRequest = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(
			{
				"firstName": patient.firstName,
				"lastName": patient.lastName,
				"dateOfBirth": patient.dob.toISOString(),
				"sex": patient.gender,
				"country": parseInt(patient.country),
				"state": patient.state,
				"city": patient.city,
				"address": patient.address + " " + patient.address2,
				"zipCode": patient.zipcode,
				"diagnosisId": parseInt(patient.diagnosis),
				"clientGroupId": parseInt(patient.group)
			}
		)
	}

	try {
		const response = await fetch(`${process.env.REACT_APP_TEP_API_BASE_URL}/provider/api/ExternalRegistration/completepatientregistration`,
			confirmRequest)
		return response
	} catch (e) {
		console.error(e)
		return e;
	}
}