import React from "react";
import { useRecoilValue } from "recoil";
import subscribedImage from "../images/Welcome.png";
import { providerState, patientState } from "../recoil/atoms";
export default function ThankYouSection() {
	const provider = useRecoilValue(providerState);
	const patient = useRecoilValue(patientState);

	return (
		<div className="thank-you-section">
			<img
				src={subscribedImage}
				alt="welcome-to-trellus-health"
				className="image-payment img-fluid"
			/>
			<p className="headline-body">Thank You</p>
			{provider.firstName && (
				<p className="headline-subtitle">Thank you, {provider.firstName}. You have succesfully signed up! Check your email inbox for an invitation to log in and complete your registration.</p>

			)}
			{patient.firstName && (
				<>
					<p className="headline-subtitle">For Joining Trellus Health®</p>
					<p>
						Thank you {patient.firstName}, your registration was processed
						successfully. Please check your email inbox for directions and next steps to create your secure account details and start your journey with Trellus Health®.
					</p>
				</>
			)}

		</div>
	);
}
