import React, { useEffect } from "react";
import { RecoilRoot } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";

// CSS
import "./css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Components
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import { Outlet } from "react-router-dom";

export default function App() {
	const navigate = useNavigate();
	let location = useLocation();

	useEffect(() => {
		if (location.pathname === "/") {
			navigate("/joinnow")
		}
	}, [location.pathname, navigate])

	return (
		<RecoilRoot>
			<Header />
			<Outlet />
			<Footer />
		</RecoilRoot>
	);
}
