import React from "react";
import imagePlan from "../images/stripe_join-now_v3.jpg";

export default function Nav() {
	return (
		<div className="col-12 col-md-auto form-subscription-cover order-1 order-md-2">
			<div className="form-subscription-cover-content">
				<div className="headline-steps">
					{/* <ul className="nav" id="steps" role="tablist">
            <li className="nav-item" role="tab">
              <a
                className={`nav-link ${props.step === 1 ? "active" : ""}`}
                id="profile-tab"
                data-toggle="pill"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <span className="headline-body">
                  <span className="tab-number"> 1 </span>
                </span>
              </a>
            </li>
            <li className="nav-item" role="tab">
              <a
                className={`nav-link ${props.step === 2 ? "active" : ""}`}
                id="consent-tab"
                data-toggle="pill"
                href="#consent"
                role="tab"
                aria-controls="consent"
                aria-selected="false"
              >
                <span className="headline-body">
                  <span className="tab-number"> 2 </span>
                </span>
              </a>
            </li>
            <li className="nav-item" role="tab">
              <a
                className={`nav-link ${props.step === 3 ? "active" : ""}`}
                id="payment-tab"
                data-toggle="pill"
                href="#payment"
                role="tab"
                aria-controls="payment"
                aria-selected="false"
              >
                <span className="headline-body">
                  <span className="tab-number"> 3 </span>
                </span>
              </a>
            </li>
          </ul> */}
				</div>
				<div className="text-center">
					{/* <p className="headline-title">IBDRISE</p>
          <p className="headline-title">$49.99/month</p> */}
				</div>
				<div className="mt-4">
					{/* <p className="h-font-weight-bold h-line-height-minimal mb-2">
            Resilience5 Challenge
          </p> */}
					{/* <ul className="bullet-list mb-3">
            <li className="bullet-list__item">
              Personalized Resilience RoadMap
            </li>
            <li className="bullet-list__item">
              1:1 session with Nurse Educator
            </li>
            <li className="bullet-list__item">
              1:1 session with Registered Dietitian
            </li>
            <li className="bullet-list__item">Dedicated Resilience Coaching</li>
            <li className="bullet-list__item">
              Customized digital resilience and self management skills building
              toolkit
            </li>
          </ul> */}
					<p className="h-font-weight-bold h-line-height-minimal mb-2">
						We are excited to have you start your Trellus Elevate™ journey!
					</p>
					{/* <ul className="bullet-list">
            <li className="bullet-list__item">
              Remote monitoring of symptoms and well-being
            </li>
            <li className="bullet-list__item">
              Customized therapy and disease monitoring notifications
            </li>
            <li className="bullet-list__item">IBD lab tracking and alerts</li>
            <li className="bullet-list__item">
              Personalized health maintenance reminders including vaccinations
              and preventative care
            </li>
            <li className="bullet-list__item">
              HIPAA compliant Personal Care Vault to store key reports
            </li>
            <li className="bullet-list__item">
              Expert, curated education, news and information
            </li>
            <li className="bullet-list__item">
              Featured IBD and Resilience content
            </li>
            <li className="bullet-list__item">
              Unlimited access to Trellus Members events
            </li>
          </ul> */}
				</div>
			</div>
			<div className="img-cover-container plan-image-container">
				<img src={imagePlan} alt="subscription-plan" />
			</div>
		</div>
	);
}
