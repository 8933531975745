import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import { useRecoilState, useRecoilValue } from "recoil";
import {
    authenticationTokenState,
    authorizationCodeState,
    externalRegistrationIdState,
    patientState,
    patientStep,
    providerFormProcessingState,
    providerWorkflowState
} from "../recoil/atoms";
import {
    completePatientRegistration,
    confirmAuthCode,
    createDiseaseSeverityAssessment, createIBSSelfEfficacyAssessment, createIBSStrengthAssessment,
    createSelfEfficacyAdultAssessment, createSelfEfficacyPediatricAssessment, createStrengthsAssessment,
    sendInvite
} from "../services/tep";

export default function AuthCodeForm() {
    const [patientInfo, setPatientInfo] = useRecoilState(patientState);
    const [step, setStep] = useRecoilState(patientStep);
    const [providerWorkflow, setProviderWorkflow] = useRecoilState(providerWorkflowState);
    const [confirmationCode, setConfirmationCode] = useRecoilState(authorizationCodeState);
    const [authenticationToken, setAuthenticationToken] = useRecoilState(authenticationTokenState);
    const externalRegistrationId = useRecoilValue(externalRegistrationIdState);
    const [isProcessing, setIsProcessing] = useRecoilState(providerFormProcessingState);
    const [errorMessages, setErrorMessages] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const submitConfirmationCode = async () => {
        setIsProcessing(true);
        if (confirmationCode && confirmationCode.length === 6) {
            console.log(externalRegistrationId, confirmationCode);
            let tokenResponse = null;
            try {
                tokenResponse = await confirmAuthCode(externalRegistrationId, confirmationCode)
            } catch (e) {
                console.error(e);
                setErrorMessages(errorMessages => [...errorMessages, e]);
                return;
            }

            if (tokenResponse?.access_token && tokenResponse?.token_type === 'Bearer') {
                setAuthenticationToken(tokenResponse.access_token)
                if (providerWorkflow && providerWorkflow.authCode) {
                    // provider registration
                    setProviderWorkflow({ personalInfo: false, authCode: false, addressInfo: true, registrationSuccess: false })
                } else {
                    // step === 2
                    // patient registration
                    let patient = null;
                    try {
                        // create patient in Elevate
                        setCurrentAction("Creating Trellus Elevate account...");
                        console.log(patientInfo);
                        patient = await completePatientRegistration(tokenResponse?.access_token, patientInfo);
                        // if patient was created and has an ID
                        if (patient.ok) {
                            // send welcome email
                            setCurrentAction("Sending welcome email...");
                            const invite = await sendInvite(tokenResponse?.access_token);
                            if (invite?.patientId !== patient?.patientId) {
                                setErrorMessages(errorMessages => [...errorMessages, invite?.error]);
                                setIsProcessing(false)
                                return;
                            }
                        } else {
                            setErrorMessages(errorMessages => [...errorMessages, patient?.status, patient.message]);
                            setIsProcessing(false)
                            return
                        }
                    } catch (e) {
                        console.error(e);
                        setErrorMessages(errorMessages => [...errorMessages, e.message]);
                        setIsProcessing(false)
                        return;
                    }

                    // attempt to sync assessment data
                    setCurrentAction("Syncing assessment data...");
                    const dataUrl = process.env.REACT_APP_TYPEFORM_DATA_URL;
                    const formId = process.env.REACT_APP_TYPEFORM_FORM_ID;
                    const emailQuery = encodeURIComponent(patientInfo.email);
                    try {
                        const data = await fetch(`${dataUrl}?formId=${formId}&query=${emailQuery}&completed=true`)
                        const json = await data.json();
                        if (json.total_items > 0) {
                            const formVariables = json.items[0].variables;
                            const type = formVariables.find(x => x.key === "q_dss_ibd_everity_classifier")?.text;
                            if (type === "Irritable Bowel Syndrome") {
                                const diseaseSeverityResponse = await createDiseaseSeverityAssessment(patient?.patientId, formVariables);
                                const strengthsResponse = await createIBSStrengthAssessment(patient?.patientId, formVariables);
                                const selfEfficacyPedsResponse = await createIBSSelfEfficacyAssessment(patient?.patientId, formVariables);
                            } else {
                                const diseaseSeverityData = formVariables.filter((item) => item.key.startsWith('q_dss'));
                                const diseaseSeverityResponse = await createDiseaseSeverityAssessment(patient?.patientId, diseaseSeverityData);

                                // check if age is over 18
                                const age = new Date().getFullYear() - patientInfo.dob.getFullYear();
                                if (age >= 18) {
                                    const strengthAssessmentData = formVariables.filter((item) => item.key.startsWith('strength'));
                                    const strengthsResponse = await createStrengthsAssessment(patient?.patientId, strengthAssessmentData);

                                    const selfEfficacyAdultData = formVariables.filter((item) => item.key.startsWith('self_adult'));
                                    const selfEfficacyAdultResponse = await createSelfEfficacyAdultAssessment(patient?.patientId, selfEfficacyAdultData);
                                } else {
                                    const selfEfficacyPedsData = formVariables.filter((item) => item.key.startsWith('self_efficacy_peds'));
                                    const selfEfficacyPedsResponse = await createSelfEfficacyPediatricAssessment(patient?.patientId, selfEfficacyPedsData);
                                }
                            }
                        }
                    } catch (e) {
                        console.error(e);
                        // setErrorMessages(errorMessages => [...errorMessages, e]);
                        // return;
                    }
                    setStep(3);
                }
            } else if (tokenResponse === "InvalidState") {
                setErrorMessages(errorMessages => [...errorMessages, "\nInvalid code! Please double check your code and try again."]);
            } else if (tokenResponse === "Code expired") {
                setErrorMessages(errorMessages => [...errorMessages, "\nExpired code! Your code has expired, please refresh the page and try again."]);
            }
        } else if (confirmationCode && confirmationCode.length < 6) {
            setErrorMessages(errorMessages => [...errorMessages, "\nYour code should be 6 digits long."]);
        }
        setIsProcessing(false);
    }

    return (
        <>
            <div className="row row-1">
                <div className="col-12">
                    <p>Check your email/phone for a confirmation code from Trellus Health®. The code will be valid for 5 minutes.</p>
                    <div className="form-group">
                        <input
                            maxLength={6}
                            className={`form-control`}
                            type="text"
                            id="confirmationCode"
                            name="confirmationCode"
                            aria-describedby="confirmation code"
                            placeholder="Your confirmation code."
                            value={confirmationCode}
                            onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            {errorMessages.length > 0 && <p style={{ color: 'red' }}>{errorMessages}</p>}
            <Button
                variant="primary"
                size="lg"
                onClick={submitConfirmationCode}
                disabled={isProcessing}
            >
                Submit
            </Button>
            {isProcessing ? <p>{currentAction}</p> : ""}
        </>
    )
}