import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ReactDatePicker from "react-datepicker";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { externalRegistrationIdState, patientState, patientStep, providerCountryState } from "../recoil/atoms";
import { countriesState, countryStatesState } from '../recoil/selectors';
import * as tepService from "../services/tep";
import { validateForm } from "../services/validations";
import Alert from "./Alert"; // green
import AlertDanger from "./AlertDanger"; // red

function InputErrorText(props) {
	return (
		<span className="text-danger" style={props.style}>
			{props.error}
		</span>
	);
}

const PatientForm = () => {
	const [isProcessing, setIsProcessing] = useState(false);
	const [patientExist, setPatientExist] = useState(false);
	const [patientInfo, setPatientInfo] = useRecoilState(patientState);
	const [step, setStep] = useRecoilState(patientStep);
	const [externalRegistrationId, setExternalRegistrationId] = useRecoilState(externalRegistrationIdState);
	const [formErrors, setFormErrors] = useState({
		firstName: "",
		lastName: "",
		dob: "",
		gender: "",
		email: "",
		mobile: "",
		country: "",
		city: "",
		state: "",
		zipcode: "",
		address: "",
		billingAddress: "",
		billingCountry: "",
		billingCity: "",
		billingState: "",
		billingZipcode: "",
		signUp: "",
	});
	const recaptchaRef = React.createRef();

	const getMonth = (date) => date.getMonth();
	const getYear = (date) => date.getFullYear();
	const range = (size, startAt = 0) => {
		return [...Array(size).keys()].map(i => i + startAt);
	}
	const [startDate, setStartDate] = useState(null);
	const years = range(getYear(new Date()), 1900);
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const countries = useRecoilValueLoadable(countriesState);
	const states = useRecoilValueLoadable(countryStatesState);
	const [country, setCountry] = useRecoilState(providerCountryState);
	const [state, setState] = useState("");
	const [consentBox, setConsentBox] = useState(null);
	const [consentChecked, setConsentChecked] = useState(false);

	// JSX element arrays
	const [countryElements, setCountryElements] = useState([]);
	const [stateElements, setStateElements] = useState([]);

	const radios = [
		{ name: 'Email', value: 0 },
		{ name: 'SMS (Text)', value: 1 },
	];
	const [radioValue, setRadioValue] = useState(0);
	const [contactButtons, setContactButtons] = useState([])

	useEffect(() => {
		const contactToggleButtons = radios.map((radio, idx) => (
			<ToggleButton
				key={idx}
				id={`radio-${idx}`}
				type="radio"
				variant={idx % 2 ? 'outline-dark' : 'outline-dark'}
				size="lg"
				name="radio"
				value={radio.value}
				checked={radioValue === radio.value}
				onChange={(e) => setRadioValue(e.currentTarget.value)}
			>
				{radio.name}
			</ToggleButton>
		))
		setContactButtons(contactToggleButtons)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [radioValue])

	useEffect(() => {
		const checkbox = <input
			id="consent"
			type="checkbox"
			checked={!!consentChecked}
			onChange={(e) => { setConsentChecked(e.target.checked); }}
		/>
		setConsentBox(checkbox)
	}, [consentChecked])

	useEffect(() => {
		switch (countries.state) {
			case 'hasValue':
				if (countries.contents) {
					const elements = Object.entries(countries.contents).map((item, index) => {
						// item[0] == "1" item[1] == "USA" strings
						return (<option id={index} key={index} value={item[0]}>{item[1]}</option>)
					});
					setCountryElements(elements);
				}
				break;
			case 'loading':
				break;
			case 'hasError':
				// setErrorMessages(errorMessages => [...errorMessages, `\n${countries.contents}`]);
				break;
			default:
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countries]);

	useEffect(() => {
		switch (states.state) {
			case 'hasValue':
				if (states.contents) {
					const elements = states.contents.map((item, index) => {
						return (<option id={index} key={index} value={item}>{item}</option>)
					});
					setStateElements(elements);
				}
				break;
			case 'loading':
				break;
			case 'hasError':
				// setErrorMessages(errorMessages => [...errorMessages, `\n${states.contents}`]);
				break;
			default:
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [states]);

	const handleChangeValue = (key, value) => {
		setPatientInfo({
			...patientInfo,
			[key]: value,
		});
	};

	const onReCaptcha = (value) => {
		setFormErrors({
			...formErrors,
			signUp: "",
		});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		// check if consent is checked
		if (!consentChecked) {
			setFormErrors({
				...formErrors,
				signUp: "You must consent to receiving communications from Trellus Health® by checking the box below."
			});
			return false;
		}
		// Check if recaptcha was checked
		if (!recaptchaRef.current.getValue()) {
			setFormErrors({
				...formErrors,
				signUp: "Please verify you are a human with the check box below.",
			});
			return false;
		}
		// validate form data
		if (!validateForm(patientInfo, setFormErrors)) return;

		setIsProcessing(true);

		try {
			// check if user email already exists
			const emailExists = await tepService.checkEmail(patientInfo.email);
			if (emailExists?.emailExist) {
				setFormErrors({
					signUp: `The email ${patientInfo.email} is already in use. Please use another one and try again.`,
				});
				setPatientExist(true);
				setIsProcessing(false);
				return;
			}

			// create auth code 
			const authCode = await tepService.createAuthCode(patientInfo, radioValue)
			if (authCode?.externalRegistrationId) {
				setExternalRegistrationId(authCode?.externalRegistrationId);
				setStep(2);
			} else {
				setFormErrors({
					signUp: authCode,
				});
			}
		} catch (e) {
			console.error(e);
			setFormErrors({
				signUp: e.message,
			});
		}
		setIsProcessing(false);
	};

	return (
		<Form onSubmit={handleSubmit} autoComplete="on">
			{patientExist && (
				<Alert title="You are already subscribed!">
					Click the link to be taken to
					<a
						className="btn-link btn-link-darkness h-text-underline"
						style={{ verticalAlign: "top" }}
						href={`${process.env.REACT_APP_ELEVATE_URL}`}
					>
						{' '}Trellus Elevate™
					</a>
				</Alert>
			)}
			<AlertDanger error={formErrors.signUp} />
			<div className="row">
				<div className="col-12 col-md-6">
					<div className="form-group">
						<label htmlFor="email">Email</label>
						<input
							maxLength={62}
							type="email"
							className={`form-control ${formErrors.email ? "is-invalid" : ""}`}
							id="email"
							name="email"
							aria-describedby="email"
							placeholder="Please type your email."
							value={patientInfo.email}
							onChange={(event) => handleChangeValue(event.target.name, event.target.value)}
						/>
						<InputErrorText error={formErrors.email} />
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="form-group">
						<label htmlFor="mobile">Mobile</label>
						<PhoneInput
							maxLength="14"
							className="form-control"
							placeholder="Enter phone number"
							defaultCountry="US"
							value={patientInfo.mobile}
							onChange={(event) => handleChangeValue('mobile', event)}
						/>
						<InputErrorText error={formErrors.mobile} />
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="form-group">
						<label htmlFor="first-name">First Name</label>
						<input
							maxLength={30}
							type="text"
							className={`form-control ${formErrors.firstName ? "is-invalid" : ""
								}`}
							id="first-name"
							name="firstName"
							aria-describedby="first-name"
							placeholder="Please type your first name."
							value={patientInfo.firstName}
							onChange={(event) => handleChangeValue(event.target.name, event.target.value)}
						/>
						<InputErrorText error={formErrors.firstName} />
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="form-group">
						<label htmlFor="last-name">Last Name</label>
						<input
							maxLength={30}
							type="text"
							className={`form-control ${formErrors.lastName ? "is-invalid" : ""
								}`}
							id="last-name"
							name="lastName"
							aria-describedby="last-name"
							placeholder="Please type your last name."
							value={patientInfo.lastName}
							onChange={(event) => handleChangeValue(event.target.name, event.target.value)}
						/>
						<InputErrorText error={formErrors.lastName} />
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="form-group date-form">
						<label htmlFor="Date-of-Birth">Date of Birth</label>
						<ReactDatePicker
							renderCustomHeader={({
								date,
								changeYear,
								changeMonth,
								decreaseMonth,
								increaseMonth,
								prevMonthButtonDisabled,
								nextMonthButtonDisabled,
							}) => (
								<div
									style={{
										margin: 10,
										display: "flex",
										justifyContent: "center",
									}}
								>
									<select
										value={getYear(date)}
										onChange={({ target: { value } }) => changeYear(value)}
									>
										{years.map((option) => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>

									<select
										value={months[getMonth(date)]}
										onChange={({ target: { value } }) =>
											changeMonth(months.indexOf(value))
										}
									>
										{months.map((option) => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
								</div>
							)}
							className={`form-control ${formErrors.dob ? "is-invalid" : ""
								}`}
							selected={startDate}
							maxDate={new Date()}
							isClearable
							placeholderText="Enter your birth date"
							onChange={(date) => {
								setStartDate(date)
								handleChangeValue('dob', date)
							}}
						/>
						<InputErrorText error={formErrors.dob} />
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="form-group">
						<label htmlFor="gender">Sex</label>
						<select
							className={`custom-select ${formErrors.gender ? "is-invalid" : ""
								}`}
							name="gender"
							id="gender"
							defaultValue={"DEFAULT"}
							onChange={(event) => handleChangeValue(event.target.name, event.target.value)}
						>
							<option disabled="" hidden="" value="DEFAULT">
								Sex
							</option>
							<option value={0}>Male</option>
							<option value={1}>Female</option>
						</select>
						<InputErrorText error={formErrors.gender} />
					</div>
				</div>
				<div className="col-12 col-md-12 ">
					<div className="form-group">
						<label htmlFor="address" className="">
							Home Address
						</label>
						<div>
							<input

								maxLength={95}
								type="text"
								className={`form-control ${formErrors.address ? "is-invalid" : ""
									}`}
								id="address"
								name="address"
								placeholder="Street address or P.O. Box"
								onChange={(event) => handleChangeValue(event.target.name, event.target.value)}
							/>
							<InputErrorText error={formErrors.address} />
						</div>
					</div>
				</div>
				<div className="col-12 col-md-12 ">
					<div className="form-group">
						<div>
							<input

								maxLength={95}
								type="text"
								className="form-control"
								id="address2"
								name="address2"
								placeholder="Apt, suite, unit, building, floor, etc"
								onChange={(event) => handleChangeValue(event.target.name, event.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-5">
					<div className="form-group">
						<label htmlFor="country">Country</label>
						<select
							className={`custom-select ${formErrors.country ? "is-invalid" : ""
								}`}
							name="country"
							id="country"
							defaultValue={"1"}
							onChange={(e) => {
								setPatientInfo({ ...patientInfo, country: e.target.value });
								setCountry(e.target.value);
							}}>
							{countryElements}
						</select>
						<InputErrorText error={formErrors.country} />
					</div>
				</div>
				<div className="col-12 col-md-5">
					<div className="form-group">
						<label htmlFor="state">State</label>
						<select
							className={`custom-select ${formErrors.state ? "is-invalid" : ""
								}`}
							name="state"
							id="state"
							defaultValue={""}
							onChange={(e) => {
								setPatientInfo({ ...patientInfo, state: e.target.value });
								setState(e.target.value);
							}}>
							{stateElements}
						</select>
						<InputErrorText error={formErrors.state} />
					</div>
				</div>
				<div className="col-12 col-md-5">
					<div className="form-group">
						<label htmlFor="city">City</label>
						<input
							pattern="^[a-zA-Z',.\s-]{1,25}$"
							maxLength={35}
							type="text"
							className={`form-control ${formErrors.city ? "is-invalid" : ""}`}
							id="city"
							name="city"
							aria-describedby="city"
							placeholder="City"
							value={patientInfo.city}
							onChange={(event) => handleChangeValue(event.target.name, event.target.value)}
						/>
						<InputErrorText error={formErrors.city} />
					</div>
				</div>
				<div className="col-12 col-md-5">
					<div className="form-group">
						<label htmlFor="zip">Zip Code</label>
						<input
							type="text"
							className={`form-control ${formErrors.zipcode ? "is-invalid" : ""
								}`}
							id="zipcode"
							name="zipcode"
							aria-describedby="zip"
							placeholder="Zip Code"
							value={patientInfo.zipcode}
							onChange={(event) => handleChangeValue(event.target.name, event.target.value)}
						/>
						<InputErrorText error={formErrors.zipcode} />
					</div>
				</div>
			</div>

			{/* check boxes and buttons */}

			<div className="text-center">
				<p>We will send you a code to verify your identity, how would you like to receive it?</p>
				<ToggleButtonGroup type="radio" name="contact" defaultValue={0}>
					{contactButtons}
				</ToggleButtonGroup>
				<p><i>I consent to receive electronic communications from Trellus Health®, Inc. via SMS or e-mail subject to the</i>{' '}
					<a
						href="https://trellushealth.com/terms-of-use"
						target="_blank"
						rel="noopener noreferrer"
					>
						Terms of Use
					</a>.
				</p>
				{consentBox}
				<label htmlFor="consent">&nbsp;I agree</label>
				<br></br>
				<div className="row">
					<div className="col-md-3"></div>
					<div className="col-md-3">
						<ReCAPTCHA
							ref={recaptchaRef}
							sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
							onChange={onReCaptcha}
						/>
					</div>
					<div className="col-md-3"></div>
				</div>
				<Button
					variant="primary"
					size="lg"
					type="submit"
					disabled={isProcessing}
				>
					{isProcessing ? (
						<div className="spinner-border text-light" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					) : (
						"Continue"
					)}
				</Button>
			</div>
		</Form>
	);
};

export default PatientForm;
