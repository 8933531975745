import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
    authenticationTokenState,
    providerCountryState,
    providerFormProcessingState,
    providerState,
    providerWorkflowState
} from "../recoil/atoms";
import { countriesState, countryStatesState } from '../recoil/selectors';
import { completeRegistration, sendInvite } from "../services/tep";
import { zipCodeValidation, zipCodeValidationCA } from '../services/validations';

export default function AddressForm() {
    const [providerWorkflow, setProviderWorkflow] = useRecoilState(providerWorkflowState);
    const [provider, setProvider] = useRecoilState(providerState);
    const authToken = useRecoilValue(authenticationTokenState);
    const [country, setCountry] = useRecoilState(providerCountryState);
    const [isProcessing, setIsProcessing] = useRecoilState(providerFormProcessingState);

    // get those from API
    const countries = useRecoilValueLoadable(countriesState);
    const states = useRecoilValueLoadable(countryStatesState);

    // JSX element arrays
    const [countryElements, setCountryElements] = useState([]);
    const [stateElements, setStateElements] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);

    const submitRegistrationInfo = async () => {
        setIsProcessing(true);
        // validate office phone, state, zip code, address
        const { officePhone, address } = provider;
        if (!officePhone) {
            setErrorMessages(errorMessages => [...errorMessages, "\nOffice phone is required."]);
        } else {
            if (!isValidPhoneNumber(officePhone)) {
                setErrorMessages(errorMessages => [...errorMessages, `\nOffice phone ${officePhone} is not a valid phone number.`]);
            }
        }
        if (!address.line1) {
            setErrorMessages(errorMessages => [...errorMessages, "\nAddress  is required."]);
        }
        if (!address.state) {
            setErrorMessages(errorMessages => [...errorMessages, "\nState is required."]);
        }
        if (!address.zip) {
            setErrorMessages(errorMessages => [...errorMessages, "\nZip code is required."]);
        } else {
            const errors = (country === "1") ? zipCodeValidation(address.zip) : zipCodeValidationCA(address.zip)
            if (errors !== "") {
                setErrorMessages(errorMessages => [...errorMessages, `\nZip code ${address.zip} is not valid.`]);
            }
        }
        if (errorMessages.length !== 0) {
            setIsProcessing(false);
            return;
        };

        try {
            const registrationResponse = await completeRegistration(authToken, provider)
            if (registrationResponse?.status === 200) {
                // send invite
                const inviteResponse = await sendInvite(authToken)
                if (inviteResponse?.status === 200) {
                    // complete registration
                    setIsProcessing(false);
                    setProviderWorkflow({ personalInfo: false, authCode: false, addressInfo: false, registrationSuccess: true })
                }
            }
        } catch (e) {
            console.error(e)
            setErrorMessages(errorMessages => [...errorMessages, `\n${e}`]);
        }
        setIsProcessing(false);
    }

    useEffect(() => {
        switch (countries.state) {
            case 'hasValue':
                if (countries.contents) {
                    const elements = Object.entries(countries.contents).map((item, index) => {
                        return (<option id={index} key={index} value={item[0]}>{item[1]}</option>)
                    });
                    setCountryElements(elements);
                }
                break;
            case 'loading':
                break;
            case 'hasError':
                setErrorMessages(errorMessages => [...errorMessages, `\n${countries.contents}`]);
                break;
            default:
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);

    useEffect(() => {
        switch (states.state) {
            case 'hasValue':
                if (states.contents) {
                    const elements = states.contents.map((item, index) => {
                        return (<option id={index} key={index} value={item}>{item}</option>)
                    });
                    setStateElements(elements);
                }
                break;
            case 'loading':
                break;
            case 'hasError':
                setErrorMessages(errorMessages => [...errorMessages, `\n${states.contents}`]);
                break;
            default:
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [states]);

    return (
        <form>
            <div className="row">
                <div className="col-12 col-md-12 ">
                    <div className="form-group">
                        <label htmlFor="organization-name" className="">
                            Organization Name
                        </label>
                        <div>
                            <input
                                maxLength={30}
                                // TODO Check Max Length from API
                                className={`form-control`}
                                type="text"
                                id="organizationName"
                                name="organizationName"
                                aria-describedby="organization name"
                                placeholder="Your organization name."
                                value={provider.providerOrganization.providerOrganizationName}
                                onChange={(e) => setProvider({
                                    ...provider,
                                    providerOrganization: {
                                        ...provider.providerOrganization,
                                        providerOrganizationName: e.target.value
                                    }
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-12 ">
                    <div className="form-group">
                        <label htmlFor="office-phone" className="">
                            Office Phone
                        </label>
                        <div>
                            <PhoneInput
                                maxLength="14"
                                className="form-control"
                                placeholder="Your office phone number."
                                defaultCountry="US"
                                value={provider.officePhone}
                                onChange={(e) => setProvider({
                                    ...provider,
                                    officePhone: e
                                })}
                            />
                        </div>
                    </div>
                </div>
                {/*<div className="col-12 col-md-12 ">*/}
                {/*    <div className="form-group">*/}
                {/*        <label htmlFor="npi-number" className="">*/}
                {/*            NPI Number*/}
                {/*        </label>*/}
                {/*        <div>*/}
                {/*            <input*/}
                {/*                maxLength={10}*/}
                {/*                type="text"*/}
                {/*                id="npiNumber"*/}
                {/*                name="npiNumber"*/}
                {/*                aria-describedby="NPI number"*/}
                {/*                placeholder="Your NPI number."*/}
                {/*                value={provider.npiNumber}*/}
                {/*                disabled*/}
                {/*                onChange={(e) => setProvider({*/}
                {/*                    ...provider,*/}
                {/*                    npiNumber: e.target.value*/}
                {/*                })}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="col-12 col-md-12 ">
                    <div className="form-group">
                        <label htmlFor="address" className="">
                            Address
                        </label>
                        <div>
                            <input
                                maxLength={95}
                                type="text"
                                className={`form-control`}
                                id="address"
                                name="address"
                                placeholder="Street address or P.O. Box"
                                onChange={(e) => setProvider({
                                    ...provider,
                                    address: {
                                        ...provider.address,
                                        line1: e.target.value
                                    }
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-12 ">
                    <div className="form-group">
                        <div>
                            <input
                                maxLength={95}
                                type="text"
                                className="form-control"
                                id="address2"
                                name="address2"
                                placeholder="Apt, suite, unit, building, floor, etc"
                                onChange={(e) => setProvider({
                                    ...provider,
                                    address: {
                                        ...provider.address,
                                        line2: e.target.value
                                    }
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5">
                    <div className="form-group">
                        <label htmlFor="country">Country</label>
                        <select
                            className={`custom-select`}
                            name="country"
                            id="country"
                            value={country}
                            onChange={(e) => {
                                setProvider({
                                    ...provider,
                                    address: {
                                        ...provider.address,
                                        country: e.target.value
                                    }
                                });
                                setCountry(e.target.value);
                            }}
                        >
                            {countryElements}
                        </select>
                    </div>
                </div>
                <div className="col-12 col-md-5">
                    <div className="form-group">
                        <label htmlFor="state">State</label>
                        <select
                            className={`custom-select`}
                            name="state"
                            id="state"
                            onChange={(e) => setProvider({
                                ...provider,
                                address: {
                                    ...provider.address,
                                    state: e.target.value
                                }
                            })}
                        >
                            {stateElements}
                        </select>
                    </div>
                </div>
                <div className="col-12 col-md-5">
                    <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input
                            pattern="^[a-zA-Z',.\s-]{1,25}$"
                            maxLength={35}
                            type="text"
                            className={`form-control`}
                            id="city"
                            name="city"
                            aria-describedby="city"
                            placeholder="City"
                            value={provider.city}
                            onChange={(e) => setProvider({
                                ...provider,
                                address: {
                                    ...provider.address,
                                    city: e.target.value
                                }
                            })}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-5">
                    <div className="form-group">
                        <label htmlFor="zip">Zip Code</label>
                        <input
                            type="text"
                            className={`form-control`}
                            id="zipcode"
                            name="zipcode"
                            aria-describedby="zip"
                            placeholder="Zip Code"
                            value={provider.zip}
                            onChange={(e) => setProvider({
                                ...provider,
                                address: {
                                    ...provider.address,
                                    zip: e.target.value
                                }
                            })}
                        />
                    </div>
                </div>
            </div>
            <div className="text-center">
                {errorMessages !== "" && <p style={{ color: 'red' }}>{errorMessages}</p>}
                <Button
                    variant="success"
                    size="lg"
                    onClick={submitRegistrationInfo}
                    disabled={isProcessing}
                >
                    Complete Sign-Up
                </Button>
            </div>
        </form>
    )
}
