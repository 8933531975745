import React, { Suspense } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { useRecoilValue } from "recoil";
import AddressForm from "../components/AddressForm";
import AuthCodeForm from "../components/AuthCodeForm";
import ProviderForm from "../components/ProviderForm";
import ThankYouSection from "../components/ThankYouSection";
import { providerWorkflowState } from "../recoil/atoms";

export default function Provider() {

    const providerWorkflow = useRecoilValue(providerWorkflowState)
    let providerWorkflowStep = null

    if (providerWorkflow.personalInfo) {
        providerWorkflowStep = <ProviderForm />
    } else if (providerWorkflow.authCode) {
        providerWorkflowStep = <AuthCodeForm />
    } else if (providerWorkflow.addressInfo) {
        providerWorkflowStep = <AddressForm />
    } else if (providerWorkflow.registrationSuccess) {
        providerWorkflowStep = <ThankYouSection />
    } else {
        providerWorkflowStep = null
    }

    return (
        <section className="subscription form-container">
            <div className="boxed">
                <div className="row form-subscription-box mt-5">
                    <div className="col-12 col-md form-subscription-content order-2 order-md-1">
                        <div>
                            <Suspense fallback={<Spinner />}>
                                {providerWorkflowStep}
                            </Suspense>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
