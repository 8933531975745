import React, { useEffect, useState } from "react"
import { useRecoilState } from "recoil";
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { providerState, providerFormProcessingState, externalRegistrationIdState, providerWorkflowState } from "../recoil/atoms";
import { checkEmail, getAuthCode } from "../services/tep";
import { emailValidation } from '../services/validations';

export default function PersonalForm() {
    const [providerWorkflow, setProviderWorkflow] = useRecoilState(providerWorkflowState);
    const [provider, setProvider] = useRecoilState(providerState);
    const [isProcessing, setIsProcessing] = useRecoilState(providerFormProcessingState);
    const [externalRegistrationId, setExternalRegistrationId] = useRecoilState(externalRegistrationIdState);

    const [errorMessages, setErrorMessages] = useState([])

    const isFormValid = () => {
        const { email, mobile, firstName, lastName } = provider;
        if (!email) {
            setErrorMessages(errorMessages => [...errorMessages, "\nEmail address is required."]);
        } else {
            if (emailValidation(email) !== "") {
                setErrorMessages(errorMessages => [...errorMessages, `\nEmail ${email} address is not valid.`]);
            }
        }
        if (!mobile) {
            setErrorMessages(errorMessages => [...errorMessages, "\nMobile phone is required."]);
        } else {
            // validate phone number with regex
            if (!isValidPhoneNumber(mobile)) {
                setErrorMessages(errorMessages => [...errorMessages, `\nMobile phone ${mobile} is not a valid phone number.`]);
            }
        }
        if (!firstName) {
            setErrorMessages(errorMessages => [...errorMessages, "\nFirst name is required."]);
        }
        if (!lastName) {
            setErrorMessages(errorMessages => [...errorMessages, "\nLast name is required."]);
        }
        if (!consentChecked) {
            setErrorMessages(errorMessages => [...errorMessages, "\nYou must consent to receiving communications from Trellus Health®."]);
        }
    }

    const submitInfo = async () => {
        setErrorMessages([]);
        isFormValid();
        if (errorMessages.length !== 0) return;
        setIsProcessing(true);
        try {
            const emailExists = await checkEmail(provider.email);
            if (emailExists?.emailExist) {
                setErrorMessages(`The email ${provider.email} is already in use. Please use another one and try again.`)
                setIsProcessing(false);
                return
            }
            const authCode = await getAuthCode(provider.email,
                provider.mobile.slice(2), // remove country code
                provider.firstName,
                provider.lastName,
                radioValue
            )
            // what does status mean?? sometimes 1 sometimes 0?
            if (authCode?.externalRegistrationId) {
                setExternalRegistrationId(authCode?.externalRegistrationId);
                setProviderWorkflow({ personalInfo: false, authCode: true, addressInfo: false, registrationSuccess: false })
            }
        } catch (e) {
            console.error(e)
            setErrorMessages([e]);
        }
        setIsProcessing(false);
    }

    // TODO get from API
    const titles = [
        "",
        "MD",
        "DO",
        "NP",
        "RN",
        "RD",
        "RDN",
        "PA",
        "LMSW",
        "LCSW",
        "MA"
    ]
    const titleElements = titles.map((item, index) => {
        return (<option id={index} key={index} value={item}>{item}</option>)
    });

    const reasons = [
        "My Gastroenterologist",
        "Athletes vs Crohn's and Colitis",
        "Connecting to Cure Crohn's and Colitis",
        "Search Engine",
        "Google Ad",
        "Facebook Ad",
        "Youtube Ad",
        "Other social media",
        "Twitter post",
        "Facebook post/group",
        "Instagram post/story",
        "Email",
        "Radio",
        "TV",
        "Newspaper",
        "Word of mouth"
    ]
    const reasonElements = reasons.map((item, index) => {
        return (<option id={index} key={index} value={item}>{item}</option>)
    });

    const radios = [
        { name: 'Email', value: 0 },
        { name: 'SMS (Text)', value: 1 },
    ];
    const [radioValue, setRadioValue] = useState(0);
    const [contactButtons, setContactButtons] = useState([])

    useEffect(() => {
        const contactToggleButtons = radios.map((radio, idx) => (
            <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
            >
                {radio.name}
            </ToggleButton>
        ))
        setContactButtons(contactToggleButtons)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [radioValue])

    const [consentBox, setConsentBox] = useState(null);
    const [consentChecked, setConsentChecked] = useState(false);

    useEffect(() => {
        const checkbox = <input
            id="consent"
            type="checkbox"
            checked={!!consentChecked}
            onChange={(e) => { setConsentChecked(e.target.checked); }}
        />
        setConsentBox(checkbox)
    }, [consentChecked])

    return (
        <form>
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            maxLength={62}
                            type="email"
                            className={`form-control`}
                            id="email"
                            name="email"
                            aria-describedby="email"
                            placeholder="Your email address."
                            value={provider.email}
                            onChange={(e) => setProvider({ ...provider, email: e.target.value })}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <label htmlFor="mobile">Mobile</label>
                        <PhoneInput
                            maxLength="14"
                            className="form-control"
                            placeholder="Your mobile phone number."
                            defaultCountry="US"
                            value={provider.mobile}
                            onChange={(e) => setProvider({ ...provider, mobile: e })}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="form-group">
                        <label htmlFor="first-name">First Name</label>
                        <input
                            maxLength={30}
                            type="text"
                            className={`form-control`}
                            id="first-name"
                            name="firstName"
                            aria-describedby="first-name"
                            placeholder="Your first name."
                            value={provider.firstName}
                            onChange={(e) => setProvider({ ...provider, firstName: e.target.value })}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-2">
                    <div className="form-group">
                        <label htmlFor="middle-initial">Middle Initial</label>
                        <input
                            maxLength={30}
                            type="text"
                            className={`form-control`}
                            id="middle-initial"
                            name="middleInitial"
                            aria-describedby="middle-initial"
                            placeholder="Your middle initial."
                            value={provider.middleInitial}
                            onChange={(e) => setProvider({ ...provider, middleInitial: e.target.value })}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="form-group">
                        <label htmlFor="last-name">Last Name</label>
                        <input
                            maxLength={30}
                            type="text"
                            className={`form-control`}
                            id="last-name"
                            name="lastName"
                            aria-describedby="last-name"
                            placeholder="Your last name."
                            value={provider.lastName}
                            onChange={(e) => setProvider({ ...provider, lastName: e.target.value })}
                        />
                    </div>
                </div>
                {/* <div className="col-12 col-md-4">
                    <div className="form-group date-form">
                        <label htmlFor="Date-of-Birth">Date of Birth</label>
                        <div className="row">
                            <div className="col-12 my-2 my-lg-0 col-lg-4">
                                <DatePicker
                                    selected={provider.dob}
                                    onChange={(date) => setProvider({ ...provider, dob: date })}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-12 col-md-4">
                    <div className="form-group">
                        <label htmlFor="gender">Sex</label>
                        <select
                            className={`custom-select ${formErrors.gender ? "is-invalid" : ""
                                }`}
                            name="gender"
                            id="gender"
                            defaultValue={"DEFAULT"}
                            onChange={(e) => setProvider({ ...provider, gender: e.target.value })}
                        >
                            <option disabled="" hidden="" value="DEFAULT">
                                Sex
                            </option>
                            <option value="0">Male</option>
                            <option value="1">Female</option>
                        </select>
                    </div>
                </div> */}
                <div className="col-12 col-md-4">
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <select
                            className={`custom-select`}
                            name="title"
                            id="title"
                            defaultValue={"DEFAULT"}
                            onChange={(e) => setProvider(
                                {
                                    ...provider,
                                    title: e.target.value,
                                    titleId: titles.indexOf(e.target.value)
                                }
                            )}
                        >
                            <option disabled="" hidden="" value="DEFAULT">
                                Title
                            </option>
                            {titleElements}
                        </select>
                    </div>
                </div>
                {/* <div className="col-12 col-md-6">
                    <div className="form-group">
                        <label htmlFor="hear_about_us">How did you hear about us?</label>
                        <select
                            className={`custom-select ${formErrors.hear_about_us ? "is-invalid" : ""
                                }`}
                            name="hear_about_us"
                            id="hear_about_us"
                            defaultValue={"DEFAULT"}
                            onChange={(e) => setProvider({ ...provider, refferal: e.target.value })}
                            >
                            <option disabled="" hidden="" readOnly value="DEFAULT">
                                Please select your answer
                            </option>
                            {reasonElements}
                        </select>
                    </div>
                </div> */}
            </div>
            <div className="text-center">
                {errorMessages !== "" && <p style={{ color: 'red' }}>{errorMessages}</p>}
                {isProcessing && (
                    <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
                <>
                    <p>We will send you a code to verify your identity, how would you like to receive it?</p>
                    <ToggleButtonGroup type="radio" name="contact" defaultValue={0}>
                        {contactButtons}
                    </ToggleButtonGroup>
                    <p><i>I consent to receive electronic communications from Trellus Health®, Inc. via SMS or e-mail subject to the</i> <a
                        href="https://trellushealth.com/terms-of-use"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Use
                    </a>.</p>
                    {consentBox}
                    <label htmlFor="consent">&nbsp;I agree</label>
                    <br></br>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={submitInfo}
                        disabled={isProcessing}
                    >
                        Continue
                    </Button>
                </>
            </div>
        </form>
    )
}