import React from "react";

export default function AlertDanger({ error }) {
  if (!error) return null;
  return (
    <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">An error ocurred!</h4>
      <p>{error}</p>
      <hr />
      <p className="mb-0">Please contact support for help.</p>
    </div>
  );
}
