import { isValidPhoneNumber } from "react-phone-number-input";

export function emailValidation(email) {
	const regex =
		/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
	if (!email || regex.test(email) === false) {
		return "This is not a valid email";
	}
	return "";
}

export function zipCodeValidation(zipcode) {
	if (zipcode === "") return "Zip code is required";

	if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode) === false)
		return "Zip code is invalid";

	return "";
}

export function zipCodeValidationCA(zipcode) {
	if (zipcode === "") return "Zip code is required";
	if (zipcode.length > 6) return "Zip code should not be more than 6 characters";

	if (/(^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$)/.test(zipcode) === false)
		return "Zip code is invalid";

	return "";
}

export function cityValidation(city) {
	if (city === "") return "City is required";

	if (/\d/.test(city)) return "City should not contains numbers";

	return "";
}

export const validateForm = (patientInfo, setFormErrors) => {
	const {
		firstName,
		lastName,
		gender,
		dob,
		email,
		mobile,
		country,
		city,
		state,
		zipcode,
		address,
	} = patientInfo;

	let yearError = "";
	if (dob && dob.getFullYear() > 2008) {
		yearError = "I'm sorry, at the current time we can only accept patients who are 14 years of age or older. Thank you!";
	}

	const errors = {
		firstName: firstName === "" ? "Your first name is required" : "",
		lastName: lastName === "" ? "Your last name is required" : "",
		gender: gender === "" ? "Please select your sex" : "",
		dob: !dob ? "Please enter your date of birth" : yearError,
		email: emailValidation(email),
		mobile: isValidPhoneNumber(mobile) ? "" : "Invalid Phone Number",
		city: cityValidation(city),
		country: country === "" ? "Please select a country" : "",
		state: state === "" ? "Please select your state" : "",
		zipcode: country === "1" ? zipCodeValidation(zipcode) : zipCodeValidationCA(zipcode),
		address: address === "" ? "Your address is required" : "",
	};

	setFormErrors(errors);
	console.log(errors)

	const isValid = Object.keys(errors).filter(
		(key) => errors[key] !== ""
	).length;
	return !isValid;
};
