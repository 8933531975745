import { atom } from "recoil";

export const providerWorkflowState = atom({
    key: 'providerWorkflowState',
    default: {
        personalInfo: true,
        authCode: false,
        addressInfo: false,
        registrationSuccess: false
    }
})

export const providerFormProcessingState = atom({
    key: 'providerFormProcessingState',
    default: false
})

export const providerState = atom({
    key: 'providerState',
    default: {
        firstName: "",
        lastName: "",
        middleInitial: "",
        email: "",
        mobile: "",
        officePhone: "",
        title: "",
        titleId: 0,
        dob: new Date(),
        gender: "",
        npiNumber: "0000000000", // default for now to avoid user collection
        address: {
            line1: "",
            line2: "",
            country: "1",
            state: "",
            zip: ""
        },
        providerOrganization: {
            // "providerOrganizationId": 0,
            "providerOrganizationName": "",
            "organizationType": 2
        },
        refferal: ""
    }
});

export const providerCountryState = atom({
    key: 'providerCountry',
    default: "1"
})

export const externalRegistrationIdState = atom({
    key: 'externalRegistrationIdState',
    default: ''
})

export const authorizationCodeState = atom({
    key: 'authorizationCodeState',
    default: ''
})

export const authenticationTokenState = atom({
    key: 'authenticationTokenState',
    default: ''
})

export const patientState = atom({
    key: 'patientState',
    default: {
        firstName: "",
        lastName: "",
        dob: null,
        gender: 0,
        email: "",
        mobile: "",
        country: "1",
        city: "",
        state: "",
        zipcode: "",
        address: "",
        address2: "",
        // specialty: 1,
        diagnosis: 1,
        // client: 1,
        group: 1000001,
    }
});

export const patientStep = atom({
    key: 'patientStep',
    default: 1
});