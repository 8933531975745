import React from "react";
import { createRoot } from 'react-dom/client';
import TagManager from "react-gtm-module";
import { datadogRum } from "@datadog/browser-rum";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import ErrorPage from './routes/ErrorPage'
import Patient from "./routes/patient";
import Provider from "./routes/provider";
import Download from "./routes/download";

const tagManagerArgs = {
	// TODO make this an env variable
	gtmId: "GTM-56QL288",
};
TagManager.initialize(tagManagerArgs);

datadogRum.init({
	applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
	clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
	site: "datadoghq.com",
	service: process.env.REACT_APP_DATADOG_RUM_SERVICE_NAME,
	env: process.env.REACT_APP_DATADOG_RUM_ENV,
	// Specify a version number to identify the deployed version of your application in Datadog
	version: process.env.REACT_APP_DATADOG_RUM_VERSION,
	sampleRate: 100,
	premiumSampleRate: 100,
	trackInteractions: true,
	defaultPrivacyLevel: "mask-user-input",
});
datadogRum.startSessionReplayRecording();

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "joinnow",
				element: <Patient />
			},
			{
				path: "provider",
				element: <Provider />
			},
			{
				path: "provider/download",
				element: (<section className="subscription form-container">
					<div className="boxed">
						<div className="row form-subscription-box mt-5">
							<div className="col-12 col-md form-subscription-content order-2 order-md-1">
								<div>
									<Download />
								</div>
							</div>
						</div>
					</div>
				</section>)
			}
		]
	},
]);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
);