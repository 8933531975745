import { selector } from 'recoil'
import { providerCountryState } from './atoms';
import { getCountries, getStates } from '../services/tep';

export const countriesState = selector({
    key: 'Countries',
    get: async () => {
        const response = await getCountries();
        return response;
    },
});

export const countryStatesState = selector({
    key: 'CurrentCountryStates',
    get: async ({ get }) => {
        const countryId = get(providerCountryState)
        const response = await getStates(countryId);
        return response;
    },
});