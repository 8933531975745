import React from "react";

export default function Alert({ title, message, children, type = "success" }) {
  if (!message && !children) return null;
  return (
    <div className={`alert alert-${type}`} role="alert">
      <h4 className="alert-heading">{title}</h4>
      <p>{message ?? children}</p>
      <hr />
      <p className="mb-0">Please contact support for help.</p>
    </div>
  );
}
