import React from "react";

export default function Header() {

	return (
		<div className="navbar-container landing-header landing-header--light">
			<div className="navbar-wrapper">
				<nav className="boxed">
					<div className="row align-items-center justify-content-between">
						<div className="col-auto">
							<a href="https://trellushealth.com/">
								<img src={process.env.REACT_APP_TRELLUS_HEALTH_LOGO_URL} alt="trellus-logo" width="360" />
							</a>
						</div>
					</div>
				</nav>
			</div>
		</div>
	);
}
