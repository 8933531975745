import React from "react";
import Image from 'react-bootstrap/Image';

import appStoreImage from "../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import googlePlayImage from "../images/google-play-badge-small.png";
import teamImage from "../images/team-image.png";

export default function Download() {

	return (
		<div className="text-center">

			<div className="thank-you-section">
				<Image
					src={teamImage}
					alt="subscription-mail"
					className="image-payment img-fluid"
				/>
				<p className="headline-body">Thank you!</p>
				<p className="headline-subtitle">You have succesfully completed your registration!</p>
				<p>
					Download our mobile apps in their respective app stores below!
				</p>
				<a href={process.env.REACT_APP_PROVIDER_IOS_APP_LINK}>
					<Image src={appStoreImage} alt="Apple App Store">
					</Image>
				</a>
				<a href={process.env.REACT_APP_PROVIDER_ANDROID_APP_LINK}>
					<Image src={googlePlayImage} alt="Google Play Store">
					</Image>
				</a>
			</div>
		</div>
	);
}