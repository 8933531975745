import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import AuthCodeForm from "../components/AuthCodeForm";
import Nav from "../components/Nav";
import PatientForm from "../components/PatientForm";
import ThankYouSection from "../components/ThankYouSection";
import { patientState, patientStep } from "../recoil/atoms";

export default function Subscription() {
	const step = useRecoilValue(patientStep);
	const [patientInfo, setPatientInfo] = useRecoilState(patientState);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		// default to DTC UC if no params are passed in URL
		const diagnosis = searchParams.get("diagnosis") || process.env.REACT_APP_DEFAULT_DIAGNOSIS;
		const group = searchParams.get("group") || process.env.REACT_APP_DEFAULT_GROUP;
		setPatientInfo({
			...patientInfo,
			diagnosis,
			group,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams, setPatientInfo]);

	return (
		<section className="subscription form-container">
			<div className="boxed">
				<div className="row form-subscription-box mt-5">
					<div className="col-12 col-md form-subscription-content order-2 order-md-1">
						<div>
							<div className="tab-content">
								{step === 1 && (
									<div
										className={`tab-pane fade show ${step === 1 ? "active" : ""}`}
										id="profile"
										role="tabpanel"
										aria-labelledby="profile-tab"
									>
										<div className="headline-title">{"Personal Profile"}</div>
										<PatientForm />
									</div>
								)}

								{step === 2 && (
									<div
										className={`tab-pane fade show ${step === 2 ? "active" : ""}`}
										id="authorisation"
										role="tabpanel"
										aria-labelledby="payment-tab"
									>
										<AuthCodeForm />
									</div>
								)}

								{step === 3 && (
									<div
										className={`tab-pane fade show ${step === 3 ? "active" : ""}`}
										id="thankyou"
										role="tabpanel"
										aria-labelledby="thankyou-tab"
									>
										<ThankYouSection />
									</div>
								)}
							</div>
						</div>
					</div>
					<Nav />
				</div>
			</div>
		</section>
	);
}
